export default [
  {
    title: 'Inicio',
    route: 'painel',
    icon: 'HomeIcon',
  },
  {
    title: 'Bot',
    icon: 'ChevronDownIcon',
    disabled: true,
  },
  {
    title: 'Cadastrar BOT',
    route: 'cadastrar-bot',
    icon: 'UserIcon',
  },
  {
    title: 'Cliente',
    icon: 'ChevronDownIcon',
    disabled: true,
  },
  {
    title: 'Cadastrar Cliente',
    route: 'cadastrar-cliente',
    icon: 'UserIcon',
  },
  {
    title: 'Listar Clientes',
    route: 'listar-clientes',
    icon: 'UserIcon',
  },
  {
    title: 'Grupo',
    icon: 'ChevronDownIcon',
    disabled: true,
  },
  {
    title: 'Cadastrar Grupo',
    route: 'painel-cadastrar-grupo',
    icon: 'UserIcon',
  },
  {
    title: 'Gerenciar Grupo',
    route: 'painel-gerenciar-grupo',
    icon: 'UserIcon',
  },
  {
    title: 'Renovar Grupos',
    route: 'renovacoes',
    icon: 'UserIcon',
  },
  {
    title: 'Missão',
    icon: 'ChevronDownIcon',
    disabled: true,
  },
  {
    title: 'Cadastrar Missão',
    route: 'painel-cadastrar-missao',
    icon: 'UserIcon',
  },
  {
    title: 'Gerenciar Solicitações',
    route: 'painel-gerenciar-solicitacoes',
    icon: 'UserIcon',
  },

]
